<template>
  <div>
    <base-page-title :titleText="pageTitle" />
    <b-col class="col-12 col-md-8 p-3 mx-auto">
      <b-card>
        <b-row class="no-gutters">
          <b-col class="col-12 col-md-6">
            <h4>
              {{ $t("page.projectDetails.general") }}
            </h4>
            <p>
              {{ $t("page.projectDetails.projectName")
              }}<span class="font-weight-bold">{{ project.name }}</span>
            </p>
            <p v-if="project.branchId">
              {{ $t("branch") + ":" }}
              <span class="font-weight-bold">
                {{ branchName() }}
              </span>
            </p>
            <p>
              {{ $t("page.projectDetails.projectStatus") }}
              <span class="font-weight-bold text-uppercase">
                {{ project.status }}
              </span>
            </p>
          </b-col>
          <b-col class="col-12 col-md-6">
            <h4>
              {{ $t("page.projectDetails.audience") }}
            </h4>
            <p>
              {{ $t("page.projectDetails.country") }}
              <span class="font-weight-bold">{{
                project.audience.country || "-"
              }}</span>
            </p>
            <p>
              {{ $t("page.projectDetails.population") }}
              <span class="font-weight-bold">
                {{ getPopulationText }}
              </span>
            </p>
          </b-col>
          <!-- <b-col class="col-12">
            <b-button v-if="isProjectStatusActive" @click="closeProject">
              Close study
            </b-button>
          </b-col> -->
        </b-row>
        <div v-if="isProjectStatusActive || isProjectStatusClosed">
          <hr class="my-3" />
          <p class="font-weight-bold">
            {{ $t("page.projectDetails.uploadAnalysisFile") }}
          </p>

          <b-row>
            <b-col>
              <input
                accept="application/json"
                ref="importFile"
                type="file"
                name="file-input"
                style="display: none"
                @change="uploadLocalFile"
              />
              <b-button
                variant="outline-secondary"
                class="mx-auto"
                size="sm"
                @click="openUploadDialog"
              >
                {{ $t("page.projectDetails.chooseFile") }}
              </b-button>
              {{ analysisFileName }}
            </b-col>
            <b-col v-if="isProjectStatusClosed" cols="auto">
              <div>
                <b-button
                  variant="primary"
                  class=""
                  @click="updateProjectAnalysis"
                  :disabled="isAnalysisFileUploaded"
                >
                  {{ $t("page.projectDetails.updateAnalysisBtn") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="isProjectStatusActive">
          <b-row class="no-gutters justify-content-between mt-3">
            <b-col class="col-auto">
              <b-button @click="downloadData" variant="primary">
                {{ $t("page.projectDetails.downloadAnswersAndSnapshots") }}
              </b-button>
            </b-col>
            <b-col class="col-auto">
              <b-button
                variant="primary"
                class=""
                @click="completeProject"
                :disabled="isAnalysisFileUploaded"
              >
                {{ $t("page.projectDetails.closeProjectBtn") }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageTitle: "page.projectDetails.title",
      analysisFile: null,
      client: null,
    };
  },
  created() {
    const clientList = this.$store.getters["clients/getClientList"];
    this.client = clientList.find((client) => client._id === this.clientId);
  },
  computed: {
    clientId() {
      return this.$route.params.clientId;
    },
    project() {
      return this.$store.getters["clients/getActiveProject"];
    },
    isProjectStatusActive() {
      return this.project.status === "active";
    },
    isProjectStatusClosed() {
      return this.project.status === "closed";
    },
    getPopulationText() {
      if (this.project.audience.population === "national")
        return "Nationally Representative Sample";
      else if (this.project.audience.population === "purchase")
        return "Primary Grocery Decision Maker";
      else return "-";
    },
    analysisFileName() {
      if (!this.analysisFile) return "";
      else if (!this.analysisFile.name) return "Not available";
      else return this.analysisFile.name;
    },
    isAnalysisFileUploaded() {
      return this.analysisFile === null;
    },
  },
  methods: {
    closeProject() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("clients/closeProject")
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    openUploadDialog() {
      this.$refs.importFile.click();
    },
    uploadLocalFile(e) {
      const src = e.target;
      const uploadedFiles = src.files;
      if (!uploadedFiles) return;
      else if (uploadedFiles.length !== 1) {
        return;
      }

      if (uploadedFiles[0].type !== "application/json") {
        console.log("Only json files allowed");
        // return;
      } else {
        this.analysisFile = uploadedFiles[0];
      }
      // console.log("ee");
      // this.createProjectAnalysis(this.analysisFile);
    },
    updateProjectAnalysis() {
      const params = {
        clientId: this.$route.params.clientId,
        projectId: this.$route.params.projectId,
        analysisFile: this.analysisFile,
      };
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("projects/createProjectAnalysis", params)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.analysisFile = null;
          this.$store.dispatch("loader/close");
        });
    },
    completeProject() {
      const params = {
        clientId: this.$route.params.clientId,
        projectId: this.$route.params.projectId,
        analysisFile: this.analysisFile,
      };
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("projects/createProjectAnalysis", params)

        .then(() => {
          return this.$store.dispatch("clients/closeProject");
        })
        .catch(() => {})
        .finally(() => {
          this.analysisFile = null;
          this.$store.dispatch("loader/close");
        });
    },

    downloadData() {
      this.$store.dispatch("projects/retrieveProjectData", {
        clientId: this.$route.params.clientId,
        projectId: this.$route.params.projectId,
      });
    },
    branchName() {
      const branch = this.client.branches.find((branch) => {
        return this.project.branchId === branch._id;
      });
      return branch.name;
    },
  },
};
</script>

<style></style>
